import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { ProjectSaleEntity, ProjectsSaleService, SourcesService } from 'src/app/client';
import { LeadsState } from '../../leads/store/leads.state';
import { SalesFacadeService } from '../sales-facade.service';

@Component({
  selector: 'app-sales-page',
  template: `
    <app-tabs
      [tabs]="[
        { label: 'Lead', value: 'lead' },
        { label: 'Opportunità', value: 'opportunity' }
      ]"></app-tabs>
    <div class="h-20 border-b border-gray flex items-center p-2 mb-4 px-5">
      <button class="border rounded-full px-2 py-1">Aggiungi Lead</button>
    </div>
    <app-leads-list
      (onClick)="onCustumerClick($event)"
      (onLeadIconClick)="onLeadIconClick($event)"
      [leads]="(leads$ | async) ?? []"></app-leads-list>
    <app-modal *ngIf="isModalOpened" [title]="'Timeline'" [classes]="'w-4/5'" (closeModal)="isModalOpened = false">
      <div class="flex justify-center my-12">
        <app-timeline></app-timeline>
      </div>
    </app-modal>
  `,
})
export class SalesPageComponent implements OnInit {
  leads$: Observable<Array<ProjectSaleEntity>>;
  isModalOpened: boolean = false;
  salesFacadeService = inject(SalesFacadeService);

  constructor(
    public router: Router,
    private projectSaleService: ProjectsSaleService
  ) {}

  ngOnInit() {
    this.leads$ = this.salesFacadeService
      .search({

        form: {},

      })
      .pipe(map(res => res.data));

    //this.store.dispatch(getSalesLeadsAction());
  }

  onCustumerClick(lead: ProjectSaleEntity) {
    this.salesFacadeService.setCurrentSaleId(lead._id);
    // this.store.dispatch(setSelectedLead({ lead }));
    this.router.navigate(['sales/detail/' + lead._id]);
  }

  onLeadIconClick(asd: ProjectSaleEntity) {
    //this.store.dispatch(getLeadTimeline({ lead: asd }));
    this.isModalOpened = true;
  }
}
